// Here you can add other styles
.bgForm{
    background-color: #f9fafa !important;
    padding: 10px 20px;
}

.GridView{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 15px;
}

.HeadText{
    display: flex;
    justify-content: space-between;
}

.TextPrice{
    color: black;
    font-weight: 700;
}
.LightText{
    font-weight: 400;
    color: rgb(63, 63, 63);
}

.bgblue{
    background-color: #FAFAFA !important;
    border-color: #001f3f !important;
}


.bgdark{
    background-color: #010101 !important;
    border-color: #010101 !important;
}

.bgbuttonblue:hover{
    background-color: #011529 !important;
    border-color: #011529 !important;
}

.clwhite{
    color: black !important;
}

.mylogin{
    color: white !important;
}

.clwhite2{
    color: white !important;
}

.clwhite a{
    color: black !important;
}

.bggray{
    background-color: #B0B8BC !important;
    border-color: #B0B8BC !important;
}




.clblack{
    color: black !important;
}

.clblack:hover{
    color: white !important;
}

.bgbutton:hover{
    background-color: #6d7274 !important;
    border-color: #6d7274 !important;
}

.sidebar-nav .nav-link.active{
    background:linear-gradient(141deg, rgba(156, 28, 243, 1) 20%, rgba(222, 63, 224, 1) 100%);
    color: white !important;
}

.breadcrumb-item + .breadcrumb-item::before{
    color: balck !important;
}

.sidebar-nav .nav-link{
    color: black !important;
}

.sidebar-nav svg.nav-icon{
    color: black !important;
}

.sidebar-nav .nav-link.active .nav-icon{
    color: white !important;
}

.simplebar-content{
    margin-right: 0px;
}

.bgdarker{
    background-color: #c2c7d6;
    border-color: #c2c7d6;
}

.bgclear{
    background-color: rgb(0, 0, 0, 0.4) !important;
    border-color: none !important;

}

.bgwhite{
    background-color: white !important;
    border-color: white !important;
}

.sidebar-toggler::before{
    background-image:url("../assets/images/left-arrow.png")
}

.sidebar-toggler:hover::before{
    background-image:url("../assets/images/left-arrow.png")
}



.sidebar-narrow-unfoldable:not(:hover){
    padding-bottom: 0px !important;
}

.relative{
    position: relative;
}

.borderdata{
    border: 3px solid #B0B8BC;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: left;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
    cursor: pointer;
}

.borderdata h2{
    font-size: 18px;
    text-align: left;
    margin-bottom: 0px;
    font-weight: 700;
}

@media screen and (max-width:650px) {
    .borderdata{
        padding: 10px 10px;
        gap: 10px;
        margin-bottom: 10px;
    }

    .borderdata h2{
        font-size: 16px;
    }
}

.btn:hover{
    background: rgb(222,63,224);
    background: linear-gradient(141deg, rgba(156, 28, 243, 1) 20%, rgba(222, 63, 224, 1) 100%);
}
